import React from 'react';
import './About.css';
import Footer from './Footer';

import bubble from './images/bubble.mp4';
import bubble1 from './images/bubble1.mp4';
import whatwe from './images/whatwe.mp4';
import howwe from './images/how-we.mp4';
import whatwedo from './images/whatwedo.mp4';
import whoweare from './images/who-we-image.mp4';
import howwework from './images/howwework.mp4';
import whowe from './images/who-we.mp4';

const VideoSection = ({ videos }) => (
  <div className="video-section" style={{ backgroundColor: "#FAFA33", padding: "20px" }}>
    <div className="video-container">
      {videos.map((video, index) => (
        <video key={index} controls={false} autoPlay muted loop className="video-player">
          <source src={video} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      ))}
    </div>
  </div>
);

function About() {
  return (
    <div>
      <div className='about-header' style={{ backgroundColor: "#7e84ec" }}></div>
      
      <VideoSection videos={[bubble, bubble1]} />
      <VideoSection videos={[whowe, whoweare]} />
      <VideoSection videos={[howwework, howwe]} />
      <VideoSection videos={[whatwe, whatwedo]} />
      
      <Footer />
    </div>
  );
}

export default About;
