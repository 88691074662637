import React from 'react';
import { useState, useRef } from 'react'; 
import { NavLink } from 'react-router-dom';
import qr from './images/QR.png'
import whatsapp from './images/qr-whatsapp.jpeg'
import logo from './images/logo1.png';
import './Footer.css'; // Import the external CSS
import { faFacebook, faXTwitter, faLinkedin,faWhatsapp } from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

function Footer() {
  
  return (
    <div> <footer className='footer-whole'>
    <div className='footer-container'>
      <div class="footer-logo">
        <img src={logo} className='logo-image-footer' alt="Logo" />
        <h1 >Three Dreams</h1>
      </div>

      <div className='footer-column-1'>
        <h3>Navigate</h3>
        <ul className='footer-list'>
          <li ><a href="/" style={{textDecoration:"none",color:"white"}}>Home</a></li>
              <li ><a href="/about" style={{textDecoration:"none",color:"white"}}>About Us</a></li>
              <li ><a href="/services" style={{textDecoration:"none",color:"white"}}>Services</a></li>
              <li ><a href="/contact" style={{textDecoration:"none",color:"white"}}>Contact Us</a></li>
          
        </ul>
      </div>
      <div className='footer-column-2'>
          <h3>Follow Us</h3>
          <ul className='footer-list'>
          <li><a href="" className='social-icon twitter'><FontAwesomeIcon icon={faXTwitter} /> Twitter</a></li>  
          <li><a href="" className='social-icon linkedin'><FontAwesomeIcon icon={faLinkedin} /> Linkedin</a></li>
          <li><a href="" className='social-icon facebook'><FontAwesomeIcon icon={faFacebook} /> Facebook</a></li>
          </ul>
      </div>
      <div className='footer-column-2'>
        <h3>Contact</h3>
        <ul className='footer-list'>
          <li>Address:3Rd Floor, Rams apartment, 13/53, 100 Feet Rd,
             near RBL BANK, Sector 10, Sector 13, Ashok Nagar, Chennai,
              Tamil Nadu 600083</li>
          <li>Email: <a href="mailto:team@threedreams.in" className='a'>team@threedreams.in</a></li>
          <li>Phone: +91 8248816007 </li>
         <div className='footer-flex-1'> <div><li className='width'>Wechat id:</li>
          <li className='width'><img src={qr} alt="we chat cibb qr" className='qr'/></li></div>
          <div> <li className='width'>WhatsApp id:</li>
          <li className='width'><img src={whatsapp} alt="we chat cibb qr" className='qr'/></li></div></div>
          {/* <li><a href="/contact" className='footer-link'>Contact Form</a></li> */}
        </ul>
      </div>
    </div>

    <div className='footer-bottom'>
      <p>&copy; {new Date().getFullYear()} Threedreams. All Rights Reserved.</p>
    </div>
  </footer></div>
  );
}

export default Footer;
