import React,{useEffect,useState,useRef} from 'react';
import './Home.css';
import flower2 from './images/flower-1.png'
import flower from './images/flower.png'
import dream from './images/dream-catcher.png'
import home_img from './images/Home bg1.jpg';
import Footer from './Footer';
import './index.css';
import home from './images/home.mp4';
import Navbar from './Navbar';
// import About from './About';
import logo from './images/logo1.png';
import { NavLink } from 'react-router-dom';
function Home(){
    const [isVisible, setIsVisible] = useState(false);
    const contentRef = useRef(null);
  
    useEffect(() => {
      const observer = new IntersectionObserver((entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            setIsVisible(true);
            observer.disconnect(); // Stop observing once visible
          }
        });
      });
  
      if (contentRef.current) {
        observer.observe(contentRef.current); // Observe the content
      }
  
      return () => {
        observer.disconnect(); // Clean up the observer on unmount
      };
    }, []);
return(
    <div>
       
         {/* <div className='home-content'>
         <img src={home_img} className='home-img1'/>
        
        
         </div> */}
         <div className='home-content-2'>
         <div className='home-content-sub'> <h2><img src={flower} alt="three dreams flower icon" className='flower-1'/>
          <h1>"Turning Ideas into Impact"
         </h1>
     {/* <img src={flower} alt="three dreams flower icon" className='flower-2' /> */}
     </h2>
               <img src={dream} alt="three dreams home dream catcher" className='home-dream-catcher'/>
           </div></div>
        {/* <div  ref={contentRef}
      className={`home-content-yellow ${isVisible ? 'visible' : ''}`}>
          <span>
            
         
          </span>
        </div> */}
        <video controls={false} autoPlay muted loop className="video-player-1">
            <source src={home} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        <Footer/>

     
    </div>
);
    
}
export default Home