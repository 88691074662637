import React, { useState, useRef } from 'react'; 
import Home from './Home';

import menu from './images/menu.png';
import logo from './images/logo1.png';import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import About from './About.jsx'; 
import Service from './Service';
import Footer from './Footer';
import Contact from './Contact';
import MouseFollower from './MouseFollower'; // Import MouseFollower
import './Navbar.css';

const App = () => {
  const [isOpen, setIsOpen] = useState(false);
  const homeRef = useRef(null);
  const aboutRef = useRef(null);
  const serviceRef = useRef(null);
  const contactRef = useRef(null);

  // Function to toggle the navbar
  const toggleNavbar = () => {
    setIsOpen(!isOpen);
  };

  // Function to scroll to the specific section
  

  return ( <Router>
    <div>  
      <MouseFollower /> {/* Add the MouseFollower here */}
      
      {/* Add Google Translate Button */}
     
      <button className="toggle-btn" onClick={toggleNavbar}>
        {isOpen ? ' ' : '  '} <img src={logo} className='logo-image' alt="Logo" />
         <h1 className='logo-h1'>Three Dreams</h1>
            
        <img src={menu} className='hidden'/>
        
      </button> 
      <navbar className={`nav ${isOpen ? 'open' : ''}`}>
        <div className='Logo-name'>
          <div className='logo'>
            <img src={logo} className='logo-img' alt="Logo" />
            <h1 >Three Dreams</h1>
          </div>
        </div>

        <div className='navbar'> 
          <ul className='nav-list'>
            <li className="nav-item" >
              <i className="fa-solid fa-address-card"></i> <a href="/" className='nav-ahref'>HOME</a>
            </li>
            <li className="nav-item">
              <i className="fa-solid fa-address-card"></i> <a href="/about" className='nav-ahref'>ABOUT</a>
            </li>
            <li className="nav-item" >
              <i className="fa-solid fa-address-card"></i>  <a href="/services" className='nav-ahref'>SERVICES</a>
            </li>
            <li className="nav-item">
              <i className="fa-solid fa-address-card"></i>  <a href="/contact" className='nav-ahref'>CONTACT</a>
            </li>
            {/* <li className="nav-item"  >
              <i className="fa-solid fa-address-card" id="google_translate_element"></i> 
            </li> */}
          </ul>
        </div>
      </navbar>

      {/* <center>
        <div className='tag-line-navbar'>
          <h1 className='tag-navbar'>Bridging China and India for Mutual Growth and Global Success</h1>
        </div>
      </center> */}
 
   
   <Routes>
     <Route path="/" element={<Home />} /> 
     <Route path="/about" element={<About />} />
     <Route path="/services" element={<Service/>} />
     <Route path="/contact" element={<Contact/>} />
   
        </Routes>

    </div> </Router>
  );
}

export default App;
