import React, { useState } from 'react';
import './Service.css';
import Footer from './Footer';
import web_designing from './images/web.jpg';
import app_Designing from './images/app.jpg';
import product_sourcing from './images/img5.png';
import packaging from './images/Designer.png';
import photography from './images/photo.png';
import videography from './images/digital.png';
import content from './images/content.png';
import branding from './images/digital.png';
import ad from './images/connection-3.jpg';

import servicewebvid from './images/service-web.mp4'
import serviceappvide from './images/service-app.mp4';

import servicephoto from './images/service-photo.mp4';
import servicevideo from './images/service-video.mp4';
import servicead from './images/service-ad.mp4';
import servicesource from './images/service-source.mp4';
import servicepakaging from './images/service-pakaging.mp4';
import servicebrand from './images/service-content.mp4'
import servicecontent from './images/service-branding.mp4'


const Service = () => {
  const [hoveredCard, setHoveredCard] = useState(null);

  const services = [
    { title: "Web Designing", content: "We create beautiful and responsive websites.", image: web_designing, video: servicewebvid },
    { title: "App Designing", content: "Designing user-friendly mobile applications.", image: app_Designing, video: serviceappvide },
    { title: "Packing Solution", content: "Providing creative packaging designs for your products .", image: packaging, video:servicepakaging  },
    { title: "Product Sourcing", content: "Helping you source high-quality products.", image: product_sourcing, video:servicesource  },
    { title: "Product Photography", content: "Capturing stunning images to showcase their features .", image: photography, video: servicephoto  },
    { title: "Product Videography", content: "Producing captivating videos to highlight the value.", image: videography, video:servicevideo  },
    { title: "Content Creation", content: "Developing engaging content to boost audience.", image: content, video:servicecontent  },
    { title: "Branding", content: "Building a strong brand  establish your business.", image:branding, video: servicebrand },
    { title: "Advertising", content: "Reach your audience through digital channels.", image: ad, video:servicead  },
    ];

  // Split services into pairs for two columns
  const columns = [];
  for (let i = 0; i < services.length; i += 3) {
    columns.push(services.slice(i, i + 3));
  }

  return (
    <div className='full'>
      <br/>
      <br/>
      <div className="service-final-content">
        {columns.map((column, columnIndex) => (
          <div className="service-column" key={columnIndex}>
            {column.map((service, index) => (
              <div
                className="service-item"
                key={index}
                style={{
                  backgroundImage: `url(${service.image})`,
                  backgroundSize: 'cover',
                  backgroundPosition: 'center',
                }}
                onMouseEnter={() => setHoveredCard(index)}
                onMouseLeave={() => setHoveredCard(null)}
              >
                {hoveredCard === index ? (
                  <video className="service-video" autoPlay muted loop>
                    <source src={service.video} type="video/mp4" />
                    Your browser does not support the video tag.
                  </video>
                ) : (
                  <>
                    <div className="service-overlay">
                      <span className="service-title">{service.title}</span>
                      <button className="like-button"></button>
                    </div>
                    <div className="service-text">
                      <p className="service-description">{service.content}</p>
                    </div>
                  </>
                )}
              </div>
            ))}
          </div>
        ))}
      </div>

      <Footer />
    </div>
  );
};

export default Service;
